import { AllEvents, createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { LazyPollieRaterInArticleBanner } from './PollieRaterInArticleBanner.lazy'

export interface PollieRaterInArticleBannerRegistrationProps {
    isSidebar?: boolean
    location: 'homepage' | 'election-landing-page'
    hasTopBorder?: boolean
}
export const PollieRaterInArticleBannerRegistration =
    createRegisterableComponent(
        'pollie-rater-in-article-banner',
        (props: PollieRaterInArticleBannerRegistrationProps, services) => {
            return (
                <LazyPollieRaterInArticleBanner
                    {...props}
                    onEvent={services.onEvent}
                />
            )
        },
    )
