import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { StyledPollieRaterButtonLink } from '../../buttons/PollieRaterButton/PollieRaterButton.styled'
import { breakpoint, calcRem } from '../../__styling'

type BannerContainerProps = {
    isSidebar?: boolean
    hasTopBorder?: boolean
}
export const StyledFederalElectionInArticleBannerContainer = styled(
    'div',
)<BannerContainerProps>(({ theme, isSidebar, hasTopBorder }) => ({
    clear: 'both',
    borderTop: hasTopBorder
        ? `1px solid ${theme.colors.borders.primary}`
        : 'none',
    borderBottom: `1px solid ${theme.colors.borders.primary}`,
    padding: calcRem(16, 22),

    [breakpoint('xs')]: !isSidebar && {
        padding: calcRem(30, 22),
    },
}))

export const StyledFederalElectionInArticleBannerTitle = styled('h2')<{
    isSidebar?: boolean
}>(({ isSidebar }) => ({
    fontSize: calcRem(32),
    lineHeight: calcRem(40),
    margin: 0,
    fontWeight: 400,
    position: 'relative',
    paddingTop: calcRem(16),

    [breakpoint('xs')]: !isSidebar && {
        fontSize: calcRem(40),
        lineHeight: calcRem(48),
        marginBottom: calcRem(24),
    },

    '&::before': {
        content: `''`,
        width: calcRem(56),
        height: calcRem(4),
        display: 'block',
        position: 'absolute',
        background: `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`,
        top: 0,
    },
}))

export const StyledFederalElectionInArticleBannerTextContainer = styled('div')<{
    isSidebar?: boolean
}>(({ theme, isSidebar }) => ({
    display: 'flex',
    flexDirection: 'column',

    '& p': {
        fontSize: calcRem(22),
        fontWeight: 700,
        color: theme.colors.text.secondary,
        marginRight: calcRem(22),

        [breakpoint('xs')]: !isSidebar && {
            marginTop: 0,
            marginBottom: 0,
        },
    },

    [breakpoint('xs')]: !isSidebar && {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}))

export const StyledFederalElectionInArticleBannerButtonLink = styled(
    StyledPollieRaterButtonLink,
)({
    minHeight: calcRem(32),
    fontSize: calcRem(13),
    lineHeight: 1.3,
})

export const StyledFederalElectionInArticleBannerSecondaryTextContainer =
    styled('p')(({ theme }) => ({
        background: tokens.thewest.colors.palette.greyErmine,
        display: 'block',
        padding: '8px',
        marginTop: '16px',

        '& a': {
            color: theme.colors.brand,
        },
    }))
