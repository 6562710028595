import { TheWestSection } from '@news-mono/common'
import {
    calcRem,
    metrics,
    theWestTextOnlySectionHeaderOverride,
} from '@news-mono/component-library'
import {
    ElectionDefinition,
    getElectionAdTargeting,
    GetRouteAdTargeting,
    getSocialImageMeta,
    isElectionFeatureEnabled,
    PageType,
    TogglesReduxState,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { getYear } from 'date-fns'
import { wrapWithElectionContext } from '../routes'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { PollieRaterBannerPositions } from '../../home/election-segment'

type CreateElectionPageArgs = {
    getAdTargeting: GetRouteAdTargeting
    electionDefinition: ElectionDefinition
    toggles: TogglesReduxState
}

export const createElectionLandingPage = ({
    getAdTargeting,
    electionDefinition,
    toggles,
}: CreateElectionPageArgs): PageType<TheWestSection> => {
    const isTheRaceEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-landing-page-the-race',
    )
    const isTheSeatsEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-landing-page-the-seats',
    )
    const isHeadToHeadEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-section-head-to-head',
    )
    const isMapEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-landing-page-map',
    )

    const isPollieRaterDriverEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        'pollie-rater-drivers',
    )

    const isFederal =
        electionDefinition.electionData?.config?.electionType.toLowerCase() ===
        'federal'
    const electionYear = getYear(
        new Date(electionDefinition.electionData?.config?.electionDate ?? ''),
    )
    const electionState = electionDefinition.electionData?.config?.state
    const meta = electionDefinition.meta

    let defaultTitle
    let collectionHeading

    if (isFederal) {
        defaultTitle = `${electionYear} Federal Election`
        collectionHeading = 'Latest Federal Election News'
    } else {
        defaultTitle = `${electionYear} ${electionState} Election`
        collectionHeading = `Latest ${electionState} Election News`
    }

    // Prioritise topic metadata over page-specific metadata.
    const seoTitle =
        meta.topic?.seoTitle ?? meta.updates?.seoTitle ?? defaultTitle
    const seoDescription =
        meta.topic?.seoDescription ?? meta.updates?.seoDescription

    /** Sets the position of the pollie rater banner.
    If the The Race & The Seats widgets are disabled, we want to show it below the Updates curation and hide the top border
    If the map is disabled, it is shown by itself, and if the map is enabled, it is shown next to the map */
    const pollieRaterBannerPosition: PollieRaterBannerPositions = isMapEnabled
        ? 'withMap'
        : !isTheRaceEnabled && !isTheSeatsEnabled
        ? 'belowUpdatesCuration'
        : 'withoutMap'

    return {
        kind: 'page',
        heading: seoTitle,
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting: getElectionAdTargeting(
            electionDefinition,
            getAdTargeting,
            'landing-page',
        ),
        pageMeta: {
            title: seoTitle,
            description: seoDescription,
            link: meta.canonical
                ? [
                      {
                          rel: 'canonical',
                          href: `${meta.canonical}politics/${electionDefinition.electionId}`,
                      },
                  ]
                : undefined,
            meta: meta.updates?.socialImage
                ? getSocialImageMeta(meta.updates.socialImage, '2400', '1260')
                : undefined,
        },
        socialMeta: {
            title: seoTitle,
            description: seoDescription,
        },
        compositions: wrapWithElectionContext([
            layout.nestedComposition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    flex: {
                        default: {
                            flexDirection: 'column',
                            gap: calcRem(24),
                        },
                        sm: {
                            gap: calcRem(24),
                        },
                    },
                    verticalGutters: ['unset', 'xxl'],
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'breaking-news',
                                        props: {
                                            dataDefinitionArgs: {
                                                type: 'curation',
                                                name: 'breaking-news',
                                                offset: 0,
                                                pageSize: 1,
                                            },
                                            isElectionPageRoute: true,
                                            removeBottomMargin: true,
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            flex: {
                                                default: {
                                                    flexDirection: 'column',
                                                    gap: calcRem(0), // Set no gap between breaking news and election navigation
                                                },
                                            },
                                            verticalSpacing: 'unset',
                                            breakpoints: {
                                                lg: {
                                                    horizontalGuttersOverride: 15,
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                ...(isHeadToHeadEnabled
                                                    ? [
                                                          layout.component({
                                                              type: 'election-head-to-head-seat-count-widget',
                                                              props: {
                                                                  electionDefinition,
                                                              },
                                                          }),
                                                      ]
                                                    : []),
                                                layout.component({
                                                    type: 'election-navigation',
                                                    props: {
                                                        electionDefinition,
                                                        activePage: 'updates',
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                horizontalSpacing: 'outerMargin',
                                flex: {
                                    default: {
                                        flexDirection: 'column',
                                        gap: calcRem(24),
                                    },
                                    lg: {
                                        gap: calcRem(48),
                                    },
                                },
                            },
                            contentAreas: {
                                main: [
                                    layout.nestedComposition({
                                        type: 'component-overrider',
                                        props: {
                                            override: () =>
                                                theWestTextOnlySectionHeaderOverride,
                                        },
                                        contentAreas: {
                                            children: [
                                                layout.nestedComposition({
                                                    type: 'box',
                                                    props: {
                                                        flex: {
                                                            default: {
                                                                flexDirection:
                                                                    'column',
                                                                gap: calcRem(0),
                                                            },
                                                        },
                                                        verticalSpacing:
                                                            'unset',
                                                    },
                                                    contentAreas: {
                                                        main: [
                                                            layout.component({
                                                                type: 'sierra',
                                                                props: {
                                                                    cardLayout:
                                                                        [
                                                                            'the-west-hero',
                                                                            {
                                                                                type: 'november',
                                                                                listLength: 4,
                                                                                hasBackground:
                                                                                    false, //So that the border appears on the last item ¯\_(ツ)_/¯
                                                                                paddingOverride:
                                                                                    calcRem(
                                                                                        16,
                                                                                        0,
                                                                                        0,
                                                                                        0,
                                                                                    ),
                                                                            },
                                                                        ],
                                                                    isLarge:
                                                                        true,
                                                                    heroBottomPadding: 8,
                                                                    dataDefinitionArgs:
                                                                        {
                                                                            type: 'curation',
                                                                            // !! Warning !! Election curations will need to be created for every future election, or the page will resolve to 'Not Found'.
                                                                            name: electionDefinition.electionId,
                                                                            pageSize: 5,
                                                                            offset: 0,
                                                                        },
                                                                    gridGap: 24,
                                                                    breakpoints:
                                                                        {
                                                                            lg: {
                                                                                gridGap: 32,
                                                                            },
                                                                        },
                                                                    sectionHeader:
                                                                        {
                                                                            heading:
                                                                                'Updates',
                                                                            isStandalonePage:
                                                                                true,
                                                                        },
                                                                },
                                                            }),
                                                            ...(pollieRaterBannerPosition ===
                                                                'belowUpdatesCuration' &&
                                                            isPollieRaterDriverEnabled
                                                                ? [
                                                                      layout.component(
                                                                          {
                                                                              type: 'pollie-rater-in-article-banner',
                                                                              props: {
                                                                                  location:
                                                                                      'election-landing-page',
                                                                                  hasTopBorder:
                                                                                      false,
                                                                              },
                                                                          },
                                                                      ),
                                                                  ]
                                                                : []),
                                                        ],
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    ...(isTheRaceEnabled
                                        ? [
                                              layout.component({
                                                  type: 'election-the-race-widget',
                                                  props: {
                                                      redirectTo: `/politics/${electionDefinition.electionId}/party-totals`,
                                                      electionDefinition,
                                                  },
                                              }),
                                          ]
                                        : []),
                                    ...(isTheSeatsEnabled
                                        ? [
                                              layout.component({
                                                  type: 'election-seat-widget',
                                                  props: {
                                                      electionDefinition,
                                                      electoratePageUrl: `/politics/${electionDefinition.electionId}/electorates`,
                                                      titleText:
                                                          'Electorate Results',
                                                  },
                                              }),
                                          ]
                                        : []),
                                    ...(pollieRaterBannerPosition ===
                                        'withoutMap' &&
                                    isPollieRaterDriverEnabled
                                        ? [
                                              layout.component({
                                                  type: 'pollie-rater-in-article-banner',
                                                  props: {
                                                      location:
                                                          'election-landing-page',
                                                  },
                                              }),
                                          ]
                                        : []),
                                    ...(pollieRaterBannerPosition ===
                                        'withMap' && isPollieRaterDriverEnabled
                                        ? [
                                              layout.nestedComposition({
                                                  type: 'athena',
                                                  props: {
                                                      containerWidth:
                                                          metrics.thewest
                                                              .siteMetrics
                                                              .mainContentWidth,
                                                      elementType: 'div',
                                                      sidebarOptions:
                                                          'visibleMobile',
                                                      hasHorizontalGutters:
                                                          false,
                                                      hasVerticalPadding: false,
                                                      sidebarSide: 'left',
                                                      hasHorizontalPadding:
                                                          false,
                                                  },
                                                  contentAreas: {
                                                      sidebar: [
                                                          layout.component({
                                                              type: 'pollie-rater-in-article-banner',
                                                              props: {
                                                                  isSidebar:
                                                                      true,
                                                                  location:
                                                                      'election-landing-page',
                                                              },
                                                          }),
                                                      ],

                                                      main: [
                                                          layout.nestedComposition(
                                                              {
                                                                  type: 'box',
                                                                  props: {
                                                                      verticalSpacing:
                                                                          'md',
                                                                      breakpoints:
                                                                          {
                                                                              sm: {
                                                                                  verticalGutters:
                                                                                      [
                                                                                          'md',
                                                                                          'unset',
                                                                                      ],
                                                                              },
                                                                              lg: {
                                                                                  verticalSpacing:
                                                                                      'xxxl',
                                                                              },
                                                                          },
                                                                  },
                                                                  contentAreas:
                                                                      {
                                                                          main: [
                                                                              layout.component(
                                                                                  {
                                                                                      type: 'election-map-overview-widget',
                                                                                      props: {
                                                                                          headingLevel:
                                                                                              'h3',
                                                                                          titleText:
                                                                                              'Map overview',
                                                                                          electionDefinition,
                                                                                          hasSidebar:
                                                                                              true,
                                                                                      },
                                                                                  },
                                                                              ),
                                                                          ],
                                                                      },
                                                              },
                                                          ),
                                                      ],
                                                  },
                                              }),
                                          ]
                                        : isMapEnabled
                                        ? [
                                              layout.nestedComposition({
                                                  type: 'box',
                                                  props: {
                                                      verticalSpacing: 'md',
                                                      breakpoints: {
                                                          lg: {
                                                              verticalSpacing:
                                                                  'xxxl',
                                                          },
                                                      },
                                                  },
                                                  contentAreas: {
                                                      main: [
                                                          layout.component({
                                                              type: 'election-map-overview-widget',
                                                              props: {
                                                                  headingLevel:
                                                                      'h3',
                                                                  titleText:
                                                                      'Map overview',
                                                                  electionDefinition,
                                                              },
                                                          }),
                                                      ],
                                                  },
                                              }),
                                          ]
                                        : []),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {},
                                        contentAreas: {
                                            main: billboardLeaderboardMrec(
                                                'one',
                                                0,
                                            ),
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {},
                                        contentAreas: {
                                            main: [
                                                layout.nestedComposition({
                                                    type: 'component-overrider',
                                                    props: {
                                                        override: () =>
                                                            theWestTextOnlySectionHeaderOverride,
                                                    },
                                                    contentAreas: {
                                                        children: [
                                                            layout.component({
                                                                type: 'uniform',
                                                                props: {
                                                                    cardLayout:
                                                                        [
                                                                            {
                                                                                type: 'november',
                                                                                listLength: 5,
                                                                            },
                                                                            [
                                                                                'large-card',
                                                                            ],
                                                                            [
                                                                                'large-card-mdNoImage',
                                                                                'large-card-mdNoImage',
                                                                            ],
                                                                            [
                                                                                'large-card-mdNoImage',
                                                                                'large-card-mdNoImage',
                                                                            ],
                                                                        ],
                                                                    section:
                                                                        'default',
                                                                    sectionHeader:
                                                                        {
                                                                            heading:
                                                                                collectionHeading,
                                                                        },
                                                                    dataDefinitionArgs:
                                                                        {
                                                                            type: 'listing',
                                                                            topics: [
                                                                                `politics/${electionDefinition.electionId}`,
                                                                            ],
                                                                            includeSubTopics:
                                                                                true,
                                                                            publicationKind:
                                                                                'article',
                                                                            paging: {
                                                                                page: 1,
                                                                                pageSize: 10,
                                                                            },
                                                                        },
                                                                    hasBorder:
                                                                        false,
                                                                },
                                                            }),
                                                        ],
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'lima',
                                                    props: {
                                                        verticalSpacing: 'md',
                                                        fontScale: 1.2,
                                                        fixedRatios: ['16:9'],
                                                        hasBackground: true,
                                                        initialColumns: 1,
                                                        intermediateColumns: 2,
                                                        finalColumns: 4,

                                                        loadMoreEnabled: true,
                                                        dataDefinitionArgs: {
                                                            type: 'listing',
                                                            topics: [
                                                                `politics/${electionDefinition.electionId}`,
                                                            ],
                                                            includeSubTopics:
                                                                true,
                                                            publicationKind:
                                                                'article',
                                                            paging: {
                                                                page: 1,
                                                                pageOffset: 10,
                                                                pageSize: 8,
                                                            },
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {},
                                        contentAreas: {
                                            main: billboardLeaderboardMrec(
                                                'two',
                                                0,
                                            ),
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ]),
    }
}
