import {
    TogglesReduxState,
    ElectionDefinition,
    isElectionFeatureEnabled,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { calcRem, metrics } from '@news-mono/component-library'
import { billboardLeaderboardMrec } from '../ad-units'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { wrapWithElectionContextNested } from '../elections/routes'

export type PollieRaterBannerPositions =
    | 'belowUpdatesCuration'
    | 'withMap'
    | 'withoutMap'
export const getElectionSegment = (
    electionDefinition: ElectionDefinition,
    toggles: TogglesReduxState,
) => {
    const { logo, electionId } = electionDefinition

    const isNavigationEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-navigation',
    )

    const isTheRaceEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-homepage-the-race',
    )

    const isTheSeatsEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-homepage-the-seats',
    )

    const isCollectionEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-homepage-collection',
    )

    const isHeadToHeadEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-homepage-head-to-head',
    )

    const isMapEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-homepage-map',
    )

    const isPollieRaterDriverEnabled =
        isFeatureEnabled(toFeatureState(toggles), 'pollie-rater-drivers') &&
        isElectionFeatureEnabled(
            toggles,
            electionDefinition.electionId,
            'election-homepage-pollie-rater-promo',
        )

    /** Sets the position of the pollie rater banner.
    If the The Race & The Seats widgets are disabled, we want to show it below the Updates curation and hide the top border
    If the map is disabled, it is shown by itself, and if the map is enabled, it is shown next to the map */
    const pollieRaterBannerPosition: PollieRaterBannerPositions = isMapEnabled
        ? 'withMap'
        : !isTheRaceEnabled && !isTheSeatsEnabled
        ? 'belowUpdatesCuration'
        : 'withoutMap'

    return wrapWithElectionContextNested([
        ...(isCollectionEnabled
            ? [
                  ...(isHeadToHeadEnabled
                      ? [
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    verticalSpacing: 'md',

                                    breakpoints: {
                                        lg: {
                                            horizontalGuttersOverride: 15,
                                        },
                                    },
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'election-head-to-head-seat-count-widget',

                                            props: {
                                                electionDefinition,
                                                headingLevel: 'h3',
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ]
                      : []),
                  ...(isNavigationEnabled
                      ? [
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    verticalSpacing: 'md',
                                    breakpoints: {
                                        lg: {
                                            verticalSpacing: 'xxxl',
                                            horizontalGuttersOverride: 15,
                                        },
                                    },
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'election-navigation',
                                            props: {
                                                electionDefinition,
                                                electionLogo: logo,
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ]
                      : []),

                  layout.nestedComposition({
                      type: 'thor',
                      props: {
                          hasBackgroundFill: false,
                          containerWidth: '100%',
                          verticalGutters: false,
                          horiziontalGutters: true,
                      },
                      contentAreas: {
                          main: [
                              ...(isTheRaceEnabled
                                  ? [
                                        layout.nestedComposition({
                                            type: 'box',
                                            props: {
                                                verticalSpacing: 'md',
                                                breakpoints: {
                                                    lg: {
                                                        verticalSpacing: 'xxxl',
                                                    },
                                                },
                                            },
                                            contentAreas: {
                                                main: [
                                                    layout.component({
                                                        type: 'election-the-race-widget',

                                                        props: {
                                                            redirectTo: `/politics/${electionDefinition.electionId}/party-totals`,
                                                            electionDefinition,
                                                            headingLevel: 'h3',
                                                        },
                                                    }),
                                                ],
                                            },
                                        }),
                                    ]
                                  : []),

                              layout.nestedComposition({
                                  type: 'box',
                                  props: {
                                      verticalSpacing: 'md',
                                      breakpoints: {
                                          lg: {
                                              verticalSpacing: 'xxxl',
                                          },
                                      },
                                  },
                                  contentAreas: {
                                      main: [
                                          layout.nestedComposition({
                                              type: 'box',
                                              props: {
                                                  flex: {
                                                      default: {
                                                          flexDirection:
                                                              'column',
                                                          gap: calcRem(0),
                                                      },
                                                  },
                                                  verticalSpacing: 'unset',
                                              },
                                              contentAreas: {
                                                  main: [
                                                      layout.component({
                                                          type: 'sierra',
                                                          props: {
                                                              cardLayout: [
                                                                  'the-west-hero',
                                                                  {
                                                                      type: 'november',
                                                                      listLength: 4,
                                                                      hasBackground:
                                                                          false, //So that the border appears on the last item ¯\_(ツ)_/¯
                                                                      paddingOverride:
                                                                          calcRem(
                                                                              16,
                                                                              0,
                                                                              0,
                                                                              0,
                                                                          ),
                                                                  },
                                                              ],
                                                              isLarge: true,
                                                              heroBottomPadding: 8,
                                                              dataDefinitionArgs:
                                                                  {
                                                                      type: 'curation',
                                                                      name: electionId,
                                                                      pageSize: 5,
                                                                      offset: 0,
                                                                  },
                                                              gridGap: 24,
                                                              breakpoints: {
                                                                  lg: {
                                                                      gridGap: 32,
                                                                  },
                                                              },
                                                          },
                                                      }),
                                                      ...(pollieRaterBannerPosition ===
                                                          'belowUpdatesCuration' &&
                                                      isPollieRaterDriverEnabled
                                                          ? [
                                                                layout.component(
                                                                    {
                                                                        type: 'pollie-rater-in-article-banner',
                                                                        props: {
                                                                            location:
                                                                                'election-landing-page',
                                                                            hasTopBorder:
                                                                                false,
                                                                        },
                                                                    },
                                                                ),
                                                            ]
                                                          : []),
                                                  ],
                                              },
                                          }),
                                      ],
                                  },
                              }),
                              ...(isTheSeatsEnabled
                                  ? [
                                        layout.nestedComposition({
                                            type: 'box',
                                            props: {
                                                verticalSpacing: 'md',
                                                breakpoints: {
                                                    lg: {
                                                        verticalSpacing: 'xxxl',
                                                    },
                                                },
                                            },
                                            contentAreas: {
                                                main: [
                                                    layout.component({
                                                        type: 'election-seat-widget',

                                                        props: {
                                                            electionDefinition,
                                                            electoratePageUrl: `/politics/${electionId}/electorates`,
                                                            headingLevel: 'h3',
                                                            titleText:
                                                                'Electorate Results',
                                                        },
                                                    }),
                                                ],
                                            },
                                        }),
                                    ]
                                  : []),
                              ...(pollieRaterBannerPosition === 'withoutMap' &&
                              isPollieRaterDriverEnabled
                                  ? [
                                        layout.component({
                                            type: 'pollie-rater-in-article-banner',
                                            props: {
                                                location: 'homepage',
                                            },
                                        }),
                                    ]
                                  : []),
                              ...(pollieRaterBannerPosition === 'withMap' &&
                              isPollieRaterDriverEnabled
                                  ? [
                                        layout.nestedComposition({
                                            type: 'athena',
                                            props: {
                                                containerWidth:
                                                    metrics.thewest.siteMetrics
                                                        .mainContentWidth,
                                                elementType: 'div',
                                                sidebarOptions: 'visibleMobile',
                                                hasHorizontalGutters: false,
                                                sidebarSide: 'left',
                                                hasHorizontalPadding: false,
                                            },
                                            contentAreas: {
                                                sidebar: [
                                                    layout.component({
                                                        type: 'pollie-rater-in-article-banner',
                                                        props: {
                                                            isSidebar: true,
                                                            location:
                                                                'homepage',
                                                        },
                                                    }),
                                                ],

                                                main: [
                                                    layout.nestedComposition({
                                                        type: 'box',
                                                        props: {
                                                            verticalSpacing:
                                                                'md',
                                                            breakpoints: {
                                                                sm: {
                                                                    verticalGutters:
                                                                        [
                                                                            'md',
                                                                            'unset',
                                                                        ],
                                                                },
                                                                lg: {
                                                                    verticalSpacing:
                                                                        'xxxl',
                                                                },
                                                            },
                                                        },
                                                        contentAreas: {
                                                            main: [
                                                                layout.component(
                                                                    {
                                                                        type: 'election-map-overview-widget',
                                                                        props: {
                                                                            headingLevel:
                                                                                'h3',
                                                                            titleText:
                                                                                'Map overview',
                                                                            electionDefinition,
                                                                            hasSidebar:
                                                                                true,
                                                                        },
                                                                    },
                                                                ),
                                                            ],
                                                        },
                                                    }),
                                                ],
                                            },
                                        }),
                                    ]
                                  : isMapEnabled
                                  ? [
                                        layout.nestedComposition({
                                            type: 'box',
                                            props: {
                                                verticalSpacing: 'md',
                                                breakpoints: {
                                                    sm: {
                                                        verticalGutters: [
                                                            'md',
                                                            'unset',
                                                        ],
                                                    },
                                                    lg: {
                                                        verticalSpacing: 'xxxl',
                                                    },
                                                },
                                            },
                                            contentAreas: {
                                                main: [
                                                    layout.component({
                                                        type: 'election-map-overview-widget',
                                                        props: {
                                                            headingLevel: 'h3',
                                                            titleText:
                                                                'Map overview',
                                                            electionDefinition,
                                                            hasSidebar: true,
                                                        },
                                                    }),
                                                ],
                                            },
                                        }),
                                    ]
                                  : []),
                          ],
                      },
                  }),

                  ...billboardLeaderboardMrec('election'),
              ]
            : []),
    ])
}
