import { AllEvents, DataLayerEventName, Features } from '@news-mono/web-common'
import React from 'react'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import {
    StyledButton,
    StyledGenericArticleBannerContainer,
    StyledHeading,
    StyledTextContainer,
} from './GenericArticleBanner.styled'

export interface GenericInArticleBannerProps {
    heading: string
    subText: string
    buttonText: string
    driverLink: string
    color: string
    buttonHoverColor?: string
    feature?: Features
    onEvent: (event: AllEvents) => void
    hasTopBorder?: boolean
}

export const GenericInArticleBanner: React.FC<GenericInArticleBannerProps> = ({
    heading,
    subText,
    buttonText,
    driverLink,
    color,
    buttonHoverColor,
    onEvent,
    hasTopBorder = true,
}) => {
    return (
        <ImpressionAvailable
            loading={false}
            available={() => {
                onEvent({
                    type: DataLayerEventName.genericArticleBannerViewed,
                    originator: `GenericArticleBanner`,
                    payload: { heading },
                })
            }}
        >
            {(ref) => (
                <StyledGenericArticleBannerContainer
                    ref={ref}
                    hasTopBorder={hasTopBorder}
                >
                    <StyledHeading dividerColor={color}>
                        {heading}
                    </StyledHeading>
                    <StyledTextContainer>
                        <p>{subText}</p>
                        <StyledButton
                            buttonColor={color}
                            buttonHoverColor={buttonHoverColor}
                            to={driverLink}
                            onClick={() =>
                                onEvent({
                                    type: DataLayerEventName.linkClicked,
                                    originator: 'GenericArticleBannerButton',
                                    payload: {
                                        text: buttonText,
                                        linkType: 'in-article',
                                        opensInNewWindow: false,
                                        url: driverLink,
                                    },
                                })
                            }
                        >
                            {buttonText}
                        </StyledButton>
                    </StyledTextContainer>
                </StyledGenericArticleBannerContainer>
            )}
        </ImpressionAvailable>
    )
}

export default GenericInArticleBanner
